import { InfoIcon, WarnIcon } from '~/icons';
import {
  ShowProblemDataWrapper, NoInfoTooltip, UnitLink,
} from './styles';
import ReactTooltip from 'react-tooltip';
import { t } from 'i18next';
import { getUserProfile } from '~/helpers/userProfile';
import { formatNumber } from '~/helpers/formatNumber';

type ShowProblemDataProps = {
  value: string | number;
  tooltipId: string;
  flags?: {
    dataIsInvalid: boolean;
    dataIsProcessed: boolean;
  }
};

export const controlColumnsConfigs = [
  {
    id: 'clientName',
    visible: true,
    label: t('cliente'),
  },
  {
    id: 'stateName',
    visible: true,
    label: t('estado'),
  },
  {
    id: 'cityName',
    visible: true,
    label: t('cidade'),
  },
  {
    id: 'unitName',
    visible: true,
    label: t('unidade'),
  },
  {
    id: 'consumption',
    visible: true,
    label: `${t('consumo')} (kWh)`,
  },
  {
    id: 'totalCharged',
    visible: true,
    label: `${t('consumo')} (R$)`,
  },
  {
    id: 'refrigerationConsumption',
    visible: true,
    label: t('consumoRefrigecao'),
  },
  {
    id: 'refrigerationConsumptionPercentage',
    visible: true,
    label: t('refrigecacaoConsumo'),
  },
  {
    id: 'consumptionByArea',
    visible: true,
    label: `${t('consumoPorM')} (kWh/m²)`,
  },
  {
    id: 'refCapacity',
    visible: true,
    label: t('capacidadeRefrigeracao'),
  },
  {
    id: 'refrigerationConsumptionByArea',
    visible: true,
    label: `m²/TR ${t('instalado')}`,
  },
];

const formatValue = (value, prefix, sufix) => (value === null || value === undefined ? '-' : `${prefix}${formatNumber(value)}${sufix}`);

export const ShowProblemData: React.FC<ShowProblemDataProps> = ({ value, tooltipId, flags }) => {
  const profile = getUserProfile();
  const isAdmin = profile.permissions?.isAdminSistema;

  let Icon = isAdmin ? WarnIcon : InfoIcon;
  let iconColor = isAdmin ? '#F3B107' : '#A9A9A9';
  let message = isAdmin ? 'dadoIncoerente' : 'naoFoiPossivelColetarDado';
  let description = isAdmin ? 'dadoIncoerenteDesc' : 'paraMaisDetalhes';

  let showValue = false;

  if (flags?.dataIsProcessed) {
    if (isAdmin) {
      Icon = InfoIcon;
      iconColor = '#A9A9A9';
      message = 'dadoTratado';
      description = 'paraMaisDetalhes';
    } else {
      showValue = true;
    }
  }

  if (flags?.dataIsInvalid) {
    showValue = false;
    if (isAdmin) {
      Icon = WarnIcon;
      iconColor = '#F3B107';
      message = 'dadoIncoerente';
      description = 'dadoIncoerenteDesc';
    } else {
      Icon = InfoIcon;
      iconColor = '#A9A9A9';
      message = 'naoFoiPossivelColetarDado';
      description = 'paraMaisDetalhes';
    }
  }

  return showValue === true ? <div>{value}</div> : (
    <ShowProblemDataWrapper>
      {isAdmin && value}
      <Icon color={iconColor} width="15" height="15" data-tip={tooltipId} data-for={`tooltip-problem-data-${tooltipId}`} />
      <ReactTooltip
        id={`tooltip-problem-data-${tooltipId}`}
        place="top"
        effect="solid"
      >
        <NoInfoTooltip>
          <Icon color="#FFFFFF" width="15" height="15" />
          <div>
            <span>{t(message)}</span>
            <p>{t(description)}</p>
          </div>
        </NoInfoTooltip>
      </ReactTooltip>
    </ShowProblemDataWrapper>
  );
};

export const StringLabel = (value, tooltipId, padding?, fontSize?) => {
  const haveInfo = value !== null && value !== undefined && value !== '';

  if (haveInfo) {
    return (
      <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
        {value}
      </div>
    );
  }

  const displayValue = value === null ? '-' : value;

  return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
      <ShowProblemData value={displayValue} tooltipId={tooltipId} />
    </div>
  );
};

export const LinkedStringLabel = (value, unitId, tooltipId, padding?, fontSize?) => {
  const haveInfo = value !== null && value !== undefined && value !== '';

  if (haveInfo) {
    return (
      <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
        <UnitLink
          style={{ color: 'black' }}
          to={`/analise/unidades/energyEfficiency/${unitId}`}
        >
          {value}
        </UnitLink>
      </div>
    );
  }

  const displayValue = value === null ? '-' : value;

  return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
      <ShowProblemData value={displayValue} tooltipId={tooltipId} />
    </div>
  );
};

export const ValueLabelWithSufix = (value, sufix, tooltipId, padding?, fontSize?) => {
  const haveInfo = value !== null && value !== undefined;
  const isPositiveValue = value > 0;
  const displayValue = formatValue(value, '', sufix);

  return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
      {haveInfo && isPositiveValue ? displayValue : <ShowProblemData value={displayValue} tooltipId={tooltipId} />}
    </div>
  );
};

export const ValueLabelWithSufixNullable = (value, sufix, tooltipId, padding?, fontSize?) => {
  const nullableData = value === null || value === undefined;
  const isPositiveValue = value > 0;
  const displayValue = formatValue(value, '', sufix);

  if (nullableData) { return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>-</div>
  ); }

  return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
      {isPositiveValue ? displayValue : <ShowProblemData value={displayValue} tooltipId={tooltipId} />}
    </div>
  );
};

export const ValueLabelWithPrefixNullable = (value, prefix, tooltipId, padding?, fontSize?) => {
  const nullableData = value === null || value === undefined;
  const isPositiveValue = value > 0;
  const displayValue = formatValue(value, prefix, '');

  if (nullableData) { return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>-</div>
  ); }

  return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
      {isPositiveValue ? displayValue : <ShowProblemData value={displayValue} tooltipId={tooltipId} />}
    </div>
  );
};

export const ValueTrated = (value, label, prefix, sufix, padding?, fontSize?) => {
  const auxValue = value[label];

  const displayValue = formatValue(auxValue, prefix, sufix);

  return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
      {(value.dataIsInvalid || value.dataIsProcessed) ? (
        <ShowProblemData
          value={displayValue}
          tooltipId={`${label}-${auxValue}-${value.unitId}`}
          flags={{
            dataIsInvalid: value.dataIsInvalid,
            dataIsProcessed: value.dataIsProcessed,
          }}
        />
      ) : displayValue}
    </div>
  );
};

export const PercentageTableLabel = (value, label, padding?, fontSize?) => {
  const auxValue = value[label];

  const displayValue = auxValue === null ? '-' : formatValue(auxValue, '', '%');

  return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
      {(value.dataIsInvalid || value.dataIsProcessed) ? (
        <ShowProblemData
          value={displayValue}
          tooltipId={`${label}-${auxValue}-${value.unitId}`}
          flags={{
            dataIsInvalid: value.dataIsInvalid,
            dataIsProcessed: value.dataIsProcessed,
          }}
        />
      ) : displayValue}
    </div>
  );
};
