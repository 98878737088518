import { AnalysisLayout } from '../Analysis/AnalysisLayout';
import { Card } from '~/components/Card';
import {
  DateExportRow,
  ExportButton,
  ModelListContainer,
  ResultsResume,
  DateButton,
  CardHeader,
  SearchBarWrapper,
  Tabs,
  TabOption,
  ModelRow,
  CardContent,
  DateWrapper,
  EmptyContent,
  DateButtonActions,
  TitleSectionDropdown,
  ResultContainer,
  NoAnalisysSelected,
  ResultContainerOverlay,
  AnalisysResult,
  LoaderOverlay,
  TableWrapper,
} from './styles';
import { DayPickerRangeController } from 'react-dates';
import { useStateVar } from '~/helpers/useStateVar';
import {
  ArrowDownIconV2,
  ExportIcon,
  SearchIcon,
} from '~/icons';
import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { ApiResps, apiCall, apiCallDownload } from '~/providers';
import {
  Button, Loader, Table,
} from '~/components';
import { toast } from 'react-toastify';
import { QuickSelectionV2 } from '~/components/QuickSelectionV2';
import {
  StringLabel, LinkedStringLabel, PercentageTableLabel, controlColumnsConfigs, ValueLabelWithSufix,
  ValueLabelWithSufixNullable,
  ValueLabelWithPrefixNullable,
  ValueTrated,
} from './TableConfigs';
import { GenerateItemColumn } from '~/components/Table';
import { Flex } from 'reflexbox';
import Pagination from 'rc-pagination';
import ModalOrderColumns from '~/components/ModalOrderColumns';
import { reorderList } from '~/helpers/reorderList';
import { ColumnTable } from '~/metadata/ColumnTable.model';
import { t } from 'i18next';
import { DocumentIcon } from '~/icons/DocumentIcon';
import { EmptyDocumentIcon } from '~/icons/EmptyDocumentIcon';
import { CalendarIcon } from '~/icons/CalendarIcon';
import { Helmet } from 'react-helmet';

export const General = (): JSX.Element => {
  const isDesktop = window.matchMedia('(min-width: 768px)');

  const [models, setModels] = useState<ApiResps['/energy/get-analysis-models-list']>();
  const [selectedModel, setSelectedModel] = useState<number>();
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [showModalOrderColumns, setShowModalOrderColumns] = useState(false);
  const [controlColumns, setControlColumns] = useState(controlColumnsConfigs);
  const [exportColumns, setExportColumns] = useState<string[]>(controlColumnsConfigs.map((col) => col.id));

  const columnsTable = [
    {
      Header: () => (
        GenerateItemColumn(t('cliente'), 'clientName', handleSortData, state.columnSort.clientName, {
          hasFilter: true,
          options: state.filtersData.clients,
          onChangeFilter: handleChangeFilters,
          onSelectAllOptions: handleSelectAllFilters,
          filterAll: state.filtersData.clients.length === state.selectedFilters.clients.length,
          value: state.selectedFilters.clients.values,
        })
      ),
      accessor: 'clientName',
      disableSortBy: true,
      Cell: (props) => StringLabel(props.row.original.clientName, `clientName-${props.row.original.unitId}`),
    },
    {
      Header: () => (
        GenerateItemColumn(t('estado'), 'stateName', handleSortData, state.columnSort.stateName, {
          hasFilter: true,
          options: state.filtersData.states,
          onChangeFilter: handleChangeFilters,
          onSelectAllOptions: handleSelectAllFilters,
          filterAll: state.filtersData.states.length === state.selectedFilters.states.length,
          value: state.selectedFilters.states.values,
        })
      ),
      accessor: 'stateName',
      disableSortBy: true,
      Cell: (props) => StringLabel(props.row.original.stateName, `stateName-${props.row.original.unitId}`),
    },
    {
      Header: () => (
        GenerateItemColumn(t('cidade'), 'cityName', handleSortData, state.columnSort.cityName, {
          hasFilter: true,
          options: state.filtersData.cities,
          onChangeFilter: handleChangeFilters,
          onSelectAllOptions: handleSelectAllFilters,
          filterAll: state.filtersData.cities.length === state.selectedFilters.cities.length,
          value: state.selectedFilters.cities.values,
        })
      ),
      accessor: 'cityName',
      disableSortBy: true,
      Cell: (props) => StringLabel(props.row.original.cityName, `cityName-${props.row.original.unitId}`),
    },
    {
      Header: () => (
        GenerateItemColumn(t('unidade'), 'unitName', handleSortData, state.columnSort.unitName, {
          hasFilter: true,
          options: state.filtersData.units,
          onChangeFilter: handleChangeFilters,
          onSelectAllOptions: handleSelectAllFilters,
          filterAll: state.filtersData.units.length === state.selectedFilters.units.length,
          value: state.selectedFilters.units.values,
        })
      ),
      accessor: 'unitName',
      disableSortBy: true,
      Cell: (props) => LinkedStringLabel(props.row.original.unitName, props.row.original.unitId, `unitName-${props.row.original.unitId}`),
    },
    {
      Header: () => (
        GenerateItemColumn(`${t('consumo')} (kWh)`, 'consumption', handleSortData, state.columnSort.consumption)
      ),
      accessor: 'consumption',
      disableSortBy: true,
      Cell: (props) => ValueTrated(props.row.original, 'consumption', '', ' kWh'),
    },
    {
      Header: () => (
        GenerateItemColumn(`${t('consumo')} (R$)`, 'totalCharged', handleSortData, state.columnSort.totalCharged)
      ),
      accessor: 'totalCharged',
      disableSortBy: true,
      Cell: (props) => ValueLabelWithPrefixNullable(props.row.original.totalCharged, 'R$ ', `totalCharged-${props.row.original.unitId}`),
    },
    {
      Header: () => (
        GenerateItemColumn(t('consumoRefrigecao'), 'refrigerationConsumption', handleSortData, state.columnSort.refrigerationConsumption)
      ),
      accessor: 'refrigerationConsumption',
      disableSortBy: true,
      Cell: (props) => ValueTrated(props.row.original, 'refrigerationConsumption', '', ' kWh'),
    },
    {
      Header: () => (
        GenerateItemColumn(t('refrigecacaoConsumo'), 'refrigerationConsumptionPercentage', handleSortData, state.columnSort.refrigerationConsumptionPercentage)
      ),
      accessor: 'refrigerationConsumptionPercentage',
      disableSortBy: true,
      Cell: (props) => PercentageTableLabel(props.row.original, 'refrigerationConsumptionPercentage'),
    },
    {
      Header: () => (
        GenerateItemColumn(`${t('consumoPorM')} (kWh/m²)`, 'consumptionByArea', handleSortData, state.columnSort.consumptionByArea)
      ),
      accessor: 'consumptionByArea',
      disableSortBy: true,
      Cell: (props) => ValueTrated(props.row.original, 'consumptionByArea', '', ' kWh/m²'),
    },
    {
      Header: () => (
        GenerateItemColumn(t('capacidadeRefrigeracao'), 'refCapacity', handleSortData, state.columnSort.refCapacity)
      ),
      accessor: 'refCapacity',
      disableSortBy: true,
      Cell: (props) => ValueLabelWithSufix(props.row.original.refCapacity, ' TR', `refCapacity-${props.row.original.unitId}`),
    },
    {
      Header: () => (
        GenerateItemColumn(`m²/TR ${t('instalado')}`, 'refrigerationConsumptionByArea', handleSortData, state.columnSort.refrigerationConsumptionByArea)
      ),
      accessor: 'refrigerationConsumptionByArea',
      disableSortBy: true,
      Cell: (props) => ValueLabelWithSufixNullable(props.row.original.refrigerationConsumptionByArea, ' m²/TR ', `refrigerationConsumptionByArea-${props.row.original.unitId}`),
    },
  ];

  const [columnsData, setColumnsData] = useState<(boolean | ColumnTable)[]>(columnsTable);

  const [state, render, setState] = useStateVar({
    showModelsCard: true,
    showTableCard: true,
    haveAnalisysResult: false,
    hasResult: true,
    isLoading: false,
    focusedInput: 'startDate' as 'endDate' | 'startDate' | null,
    showResult: false,
    startDate: moment().startOf('month').toDate() as Date | moment.Moment,
    endDate: moment().toDate() as Date | moment.Moment,
    tomorrow: moment(moment().add(1, 'days').format('YYYY-MM-DD')),
    totalItems: { totalCities: 0, totalStates: 0, totalUnits: 0 },
    unitsList: [] as any,
    showCalendar: false,
    debounceTimer: null as null | NodeJS.Timeout,
    filtersData: {} as any,
    selectedFilters: {
      clients: {
        label: t('cliente'),
        values: [],
      },
      states: {
        label: t('estado'),
        values: [],
      },
      cities: {
        label: t('cidade'),
        values: [],
      },
      units: {
        label: t('unidade'),
        values: [],
      },
    } as any,
    filterAll: {
      clients: false,
      states: false,
      cities: false,
      units: false,
    },
    columnSort: {
      clientName: {
        column: 'clientName',
        desc: false,
      },
      stateName: {
        column: 'stateName',
        desc: false,
      },
      cityName: {
        column: 'cityName',
        desc: false,
      },
      unitName: {
        column: 'unitName',
        desc: false,
      },
      consumption: {
        column: 'consumption',
        desc: false,
      },
      totalCharged: {
        column: 'totalCharged',
        desc: false,
      },
      refrigerationConsumption: {
        column: 'refrigerationConsumption',
        desc: false,
      },
      refrigerationConsumptionPercentage: {
        column: 'refrigerationConsumptionPercentage',
        desc: false,
      },
      consumptionByArea: {
        column: 'consumptionByArea',
        desc: false,
      },
      refCapacity: {
        column: 'refCapacity',
        desc: false,
      },
      refrigerationConsumptionByArea: {
        column: 'refrigerationConsumptionByArea',
        desc: false,
      },
    },
    currentSort: {
      field: '',
      type: '',
    },
    pagination: {
      itemsPerPage: 20,
      totalItems: 0,
      currentPage: 1,
    },
  });

  const getUnitListInfo = async (resetPagination = true) => {
    try {
      setState({ isLoading: true });

      const filterParams = {
        unitIds: state.selectedFilters.units.values.map((filter) => filter.value),
        stateIds: state.selectedFilters.states.values.map((filter) => filter.value),
        cityIds: state.selectedFilters.cities.values.map((filter) => filter.value),
        clientIds: state.selectedFilters.clients.values.map((filter) => filter.value),
      };

      const sortParams = {} as {orderByField: string, orderByType: string };

      if (state.currentSort.field && state.currentSort.type) {
        sortParams.orderByField = state.currentSort.field;
        sortParams.orderByType = state.currentSort.type;
      }

      if (resetPagination) {
        state.pagination.currentPage = 1;
      }

      const items = await apiCall('/energy/get-analysis-list', {
        startDate: moment(state.startDate).toISOString().substring(0, 19),
        endDate: moment(state.endDate).toISOString().substring(0, 19),
        page: state.pagination.currentPage,
        pageSize: state.pagination.itemsPerPage,
        ...sortParams,
        ...filterParams,
      });

      state.pagination.totalItems = items.totalItems;

      setState({
        unitsList: items.unitsList,
        totalItems: {
          totalCities: items.totalCITIES,
          totalUnits: items.totalItems,
          totalStates: items.totalSTATES,
        },
        haveAnalisysResult: true,
        isLoading: false,
      });

      render();
    } catch (error) {
      console.log(error);
      setState({ isLoading: false });
      render();
    }
  };

  const handleChangeFilters = (name, filters) => {
    switch (name) {
      case 'Cliente':
        state.selectedFilters.clients.values = filters.map((filter) => state.filtersData.clients.find((filterData) => filterData.value === filter));
        break;
      case 'Estado':
        state.selectedFilters.states.values = filters.map((filter) => state.filtersData.states.find((filterData) => filterData.value === filter));
        break;
      case 'Cidade':
        state.selectedFilters.cities.values = filters.map((filter) => state.filtersData.cities.find((filterData) => filterData.value === filter));
        break;
      case 'Unidade':
        state.selectedFilters.units.values = filters.map((filter) => state.filtersData.units.find((filterData) => filterData.value === filter));
        break;
      default:
        break;
    }

    handleGetFilters();
    getUnitListInfo();
    render();
  };

  const handleSelectAllFilters = (name, filterAll) => {
    switch (name) {
      case 'Cliente':
        state.selectedFilters.clients.values = filterAll ? state.filtersData.clients : [];
        state.filterAll.clients = filterAll;
        break;
      case 'Estado':
        state.selectedFilters.states.values = filterAll ? state.filtersData.states : [];
        state.filterAll.states = filterAll;
        break;
      case 'Cidade':
        state.selectedFilters.cities.values = filterAll ? state.filtersData.cities : [];
        state.filterAll.cities = filterAll;
        break;
      case 'Unidade':
        state.selectedFilters.units.values = filterAll ? state.filtersData.units : [];
        state.filterAll.units = filterAll;
        break;
      default:
        break;
    }

    handleGetFilters();
    getUnitListInfo();
    render();
  };

  const handleSortData = (column) => {
    sortDataColumn(state, column);
    getUnitListInfo();
    render();
  };

  const handleGetModels = async (modelName = '') => {
    try {
      const models = await apiCall('/energy/get-analysis-models-list', {
        general: true,
        name: modelName,
      });
      setModels(models);
    } catch (e) {
      toast.error(t('naoFoiPossivelBuscarInformacoesModelos'));
    }
  };

  const handleGetFilters = async () => {
    try {
      const filtersParams = {
        clients: state.selectedFilters.clients.values.map((filter) => filter.value),
        states: state.selectedFilters.states.values.map((filter) => filter.value),
        cities: state.selectedFilters.cities.values.map((filter) => filter.value),
      };
      const filters = await apiCall('/energy/get-energy-analysis-filters', {
        startDate: moment(state.startDate).toISOString().substring(0, 19),
        endDate: moment(state.endDate).toISOString().substring(0, 19),
        ...filtersParams,
      });
      state.filtersData = {
        clients: filters.clients.map((client) => ({ name: client.name, value: client.id })),
        states: filters.states.map((state) => ({ name: state.name, value: state.id })),
        cities: filters.cities.map((city) => ({ name: city.name, value: city.id })),
        units: filters.units.map((unit) => ({ name: unit.name, value: unit.id })),
      };

      render();
    } catch (e) {
      toast.error(t('naoFoiPossivelBuscarInformacoesFiltros'));
    }
  };

  const handleRemoveAllFilters = () => {
    state.selectedFilters.clients.values = [];
    state.selectedFilters.states.values = [];
    state.selectedFilters.cities.values = [];
    state.selectedFilters.units.values = [];

    handleGetFilters();
    getUnitListInfo();
    render();
  };

  const handleRemoveFilter = (columnKey: string, filterIndex: number) => {
    state.selectedFilters[columnKey].values.splice(filterIndex, 1);

    handleGetFilters();
    getUnitListInfo();
    render();
  };

  const handleRemoveCategoryFilter = (columnKey: string) => {
    state.selectedFilters[columnKey].values = [];

    handleGetFilters();
    getUnitListInfo();
    render();
  };

  const handleAnalysisClick = async () => {
    if (!selectedModel) {
      toast.error(t('naoTemModeloSelecionado'));

      return;
    }

    getUnitListInfo();
    state.showResult = true;
    state.showModelsCard = !state.showModelsCard;
    render();
  };

  const handleChangeColumns = (columnId) => {
    const toggleVisibility = (column) => ({
      ...column,
      visible: column.id === columnId ? !column.visible : column.visible,
    });

    const updatedColumns = controlColumns.map(toggleVisibility);
    setControlColumns(updatedColumns);
    handleSetExportColumns(updatedColumns);

    const newColumnsData = updatedColumns.map((column) => {
      if (column.visible === true) {
        return columnsTable.find((columnTable) => columnTable.accessor === column.id) ?? false;
      }
      return false;
    });

    setColumnsData(newColumnsData);

    return updatedColumns.some((column) => column.visible);
  };

  const handleSetColumnsData = (columns) => {
    setControlColumns(columns);
    handleSetExportColumns(columns);

    const newColumnsData = columns.map((column) => {
      if (column.visible === true) {
        return columnsTable.find((columnTable) => {
          if (typeof columnTable !== 'boolean') {
            return columnTable.accessor === column.id;
          }

          return false;
        }) ?? false;
      }
      return false;
    });

    setColumnsData(newColumnsData);
  };

  const searchInputDebounce = (searchText) => {
    if (state.debounceTimer) clearTimeout(state.debounceTimer);
    state.debounceTimer = setTimeout(
      () => handleGetModels(searchText),
      300,
    );
  };

  function handleSetExportColumns(columns) {
    const columnsToExport: string[] = [];
    for (const col of columns) {
      if (col?.visible) {
        columnsToExport.push(col.id);
      }
    }
    setExportColumns(columnsToExport);
  }

  async function handleExportEnergyAnalysis(startDate: Moment, endDate: Moment) {
    try {
      setState({ isLoading: true });
      render();

      const filterParams = {
        unitIds: state.selectedFilters.units.values.map((filter) => filter.value),
        stateIds: state.selectedFilters.states.values.map((filter) => filter.value),
        cityIds: state.selectedFilters.cities.values.map((filter) => filter.value),
        clientIds: state.selectedFilters.clients.values.map((filter) => filter.value),
      };

      const sortParams = {} as {orderByField: string, orderByType: string };

      if (state.currentSort.field && state.currentSort.type) {
        sortParams.orderByField = state.currentSort.field;
        sortParams.orderByType = state.currentSort.type;
      }

      const exportResponse = await apiCallDownload('/energy/export-energy-analysis-list', {
        columnsToExport: exportColumns,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        ...sortParams,
        ...filterParams,
      });

      const link: any = document.getElementById('downloadLink');
      if (link.href !== '#') window.URL.revokeObjectURL(link.href);
      link.href = window.URL.createObjectURL(exportResponse.data);
      link.download = exportResponse.headers.filename || `${t('nomeArquivoAnaliseEnergia')}.xlsx`;
      link.click();

      toast.success(t('sucessoExportacao'));
    } catch (error) {
      console.log(error);
      toast.error(t('naoFoiPossivelExportar'));
    } finally {
      setState({ isLoading: false });
      render();
    }
  }

  async function handleExport() {
    if (state.isLoading) return;
    if (!state.startDate || !state.endDate) {
      return toast.warn(t('selecioneAlgumPeriodoExportar'));
    }

    if (!selectedModel) {
      return toast.warn(t('selecioneModeloParaExportar'));
    }

    const modelToExport = models?.find((x) => x.ID = selectedModel);

    if (modelToExport?.NAME !== 'Energia') {
      toast.warn(t('erroNaoImplementado'));
    } else {
      await handleExportEnergyAnalysis(moment(state.startDate), moment(state.endDate));
    }
  }

  useEffect(() => {
    handleGetFilters();
    handleGetModels();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Helmet>
        <title>{t('dielEnergiaGeral')}</title>
      </Helmet>
      <AnalysisLayout />
      <TitleSectionDropdown
        isOpen={state.showModelsCard}
        onClick={() => {
          state.showModelsCard = !state.showModelsCard;
          render();
        }}
      >
        <span>{t('selecionarAnalise')}</span>
        <div>
          <ArrowDownIconV2 color="#202370" />
        </div>
      </TitleSectionDropdown>

      {state.showModelsCard && (
        <Card>
          <CardContent>
            <CardHeader>
              <DocumentIcon />
              <div>
                <h1>{t('modelos')}</h1>
                <p>{t('selecioneModelo')}</p>
              </div>
            </CardHeader>
            <SearchBarWrapper>
              <label htmlFor="" />
              <input
                type="text"
                placeholder={t('pesquisar')}
                onChange={(e) => {
                  searchInputDebounce(e.target.value);
                }}
              />
              <div>
                <SearchIcon />
              </div>
            </SearchBarWrapper>
            <div>
              <Tabs>
                <TabOption active>{t('geral')}</TabOption>
                {/* <TabOption>
                        Meus modelos
                      </TabOption> */}
              </Tabs>
              <ModelListContainer>
                {models && models?.length > 0 ? (
                  models.map((model) => (
                    <ModelRow
                      key={`model-row-${model.ID}`}
                      onClick={() => setSelectedModel(model.ID)}
                      isSelected={selectedModel === model.ID}
                    >
                      <p>{model.NAME}</p>
                      {/* <EditPenIcon /> */}
                    </ModelRow>
                  ))
                ) : (
                  <EmptyContent>
                    <EmptyDocumentIcon />
                    <p>{t('naoPossuiModelo')}</p>
                  </EmptyContent>
                )}
              </ModelListContainer>
            </div>
            <Button variant={selectedModel ? 'primary' : 'disabled'} onClick={handleAnalysisClick}>
              {t('botaoAnalisar')}
            </Button>
          </CardContent>
        </Card>
      )}

      <TitleSectionDropdown
        isOpen={state.showTableCard}
        onClick={() => {
          state.showTableCard = !state.showTableCard;
          render();
        }}
      >
        <span>{t('resultadoDaAnalise')}</span>
        <div>
          <ArrowDownIconV2 color="#202370" />
        </div>
      </TitleSectionDropdown>

      {state.showTableCard && (
        <ResultContainer>
          <DateExportRow>
            <DateWrapper>
              <div style={{ width: 'min-content' }}>
                <DateButton
                  className="date-picker-no-close"
                  onClick={() => {
                    setIsShowCalendar(!isShowCalendar);
                  }}
                  disabled={state.isLoading}
                >
                  <CalendarIcon />
                  <p className="date-picker-no-close">
                    <span
                      className="date-picker-no-close"
                      onClick={() => {
                        state.focusedInput = 'startDate';
                        render();
                      }}
                    >
                      {moment(state.startDate)
                        .format('DD MMM YYYY')
                        .toLowerCase()}
                    </span>
                    {' '}
                    -
                    {' '}
                    <span
                      className="date-picker-no-close"
                      onClick={() => {
                        state.focusedInput = 'endDate';
                        render();
                      }}
                    >
                      {moment(state.endDate)
                        .format('DD MMM YYYY')
                        .toLowerCase()}
                    </span>
                  </p>
                  {isShowCalendar && (
                  <DayPickerRangeController
                    onDatesChange={({ startDate, endDate }) => {
                      setState({ startDate, endDate });
                      handleGetFilters();
                      getUnitListInfo();
                      render();
                    }}
                    onFocusChange={(focusedInput) => {
                      setState({
                        focusedInput: !focusedInput ? 'startDate' : focusedInput,
                      });
                    }}
                    focusedInput={state.focusedInput}
                    startDate={moment(state.startDate)}
                    endDate={moment(state.endDate)}
                    isOutsideRange={(d) => !d.isBefore(state.tomorrow)}
                    renderCalendarInfo={null}
                    onOutsideClick={(e) => {
                      if (e.target.className === 'date-picker-no-close') return;

                      setIsShowCalendar(false);
                    }}
                  />
                  )}
                </DateButton>
                <DateButtonActions disabled={state.isLoading}>
                  <span
                    className="date-picker-no-close"
                    onClick={() => {
                      setState({
                        startDate: moment().startOf('month').toDate(),
                        endDate: moment().toDate(),
                      });
                      getUnitListInfo();
                      render();
                    }}
                  >
                    {t('limpar')}
                  </span>
                  <QuickSelectionV2
                    dateRanges={[
                      { label: t('hoje'), start: () => moment(), end: () => moment() },
                      { label: t('ontem'), start: () => moment().subtract(1, 'days'), end: () => moment().subtract(1, 'days') },
                      { label: t('semanaAtual'), start: () => moment().startOf('week'), end: () => moment() },
                      { label: t('semanaPassada'), start: () => moment().subtract(7, 'days').startOf('week'), end: () => moment() },
                      { label: t('ultimos7dias'), start: () => moment().subtract(7, 'days'), end: () => moment() },
                      { label: t('ultimos30dias'), start: () => moment().subtract(30, 'days'), end: () => moment() },
                    ]}
                    setDate={(startDate, endDate) => {
                      setState({
                        startDate: moment(startDate),
                        endDate: moment(endDate),
                      });
                      getUnitListInfo();
                      render();
                    }}
                  />
                </DateButtonActions>
              </div>
            </DateWrapper>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
              <ExportButton
                onClick={() => setShowModalOrderColumns(!showModalOrderColumns)}
              >
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 12 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.0013 14.3333L6.0013 1M10.668 12.3333L10.668 3M1.33463 12.3333L1.33463 3"
                    stroke="#363BC4"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p style={{ margin: '0%' }}>{t('colunas')}</p>
              </ExportButton>
              <a href="#" style={{ display: 'none' }} id="downloadLink" />
              <ExportButton onClick={() => handleExport()} disabled={state.isLoading}>
                <ExportIcon />
                <p style={{ margin: '0%' }}>{t('botaoExportar')}</p>
              </ExportButton>
            </div>
          </DateExportRow>
          <div style={{ position: 'relative' }}>
            {!state.haveAnalisysResult ? (
              <NoAnalisysSelected>
                <EmptyDocumentIcon />
                <span>{t('resultadoDaAnalise')}</span>
                <p>
                  {t('gerarAnalise')}
                  <b>
                    {' '}
                    {t('analisarNegrito')}
                  </b>
                  .
                </p>
              </NoAnalisysSelected>
            ) : (
              <AnalisysResult>
                <ResultsResume>
                  <div>
                    <b>Unidades</b>
                    <p>{state.totalItems.totalUnits ?? '-'}</p>
                  </div>
                  <div>
                    <b>Estados</b>
                    <p>{state.totalItems.totalStates ?? '-'}</p>
                  </div>
                  <div>
                    <b>Cidades</b>
                    <p>{state.totalItems.totalCities ?? '-'}</p>
                  </div>
                </ResultsResume>
                <div>
                  <Card noPaddingRelative>
                    <>
                      {controlColumns.some(
                        (column) => column.visible === true,
                      ) ? (
                        <TableWrapper>
                          <Table
                            columns={columnsData.filter(Boolean)}
                            data={state.unitsList}
                            noDataComponent={NoDataInTable}
                            noBorderBottom
                            handleRemoveAllFilters={handleRemoveAllFilters}
                            handleRemoveCategoryFilter={handleRemoveCategoryFilter}
                            handleRemoveFilter={handleRemoveFilter}
                            filtersSelected={state.selectedFilters}
                            style={{
                              boxShadow: 'none',
                              minHeight: '200px',
                              overflowX: state.unitsList.length ? 'auto' : 'hidden',
                              overflowY: state.unitsList.length > 7 ? 'auto' : 'hidden',
                            }}
                          />
                        </TableWrapper>
                        ) : <NoDataInTable />}

                      {showModalOrderColumns && (
                        <ModalOrderColumns
                          handleCancelModal={() => {
                            setShowModalOrderColumns(false);
                          }}
                          handleSubmitModal={() => setShowModalOrderColumns(false)}
                          columns={controlColumns}
                          handleChangeColumns={handleChangeColumns}
                          isDesktop={isDesktop.matches}
                          handleDragColumn={(dragIndex, hoverIndex) => {
                            setControlColumns((prevState) => {
                              const dataAux = reorderList(prevState, dragIndex, hoverIndex);
                              handleSetExportColumns(dataAux);
                              return dataAux;
                            });
                            setColumnsData((prevState) => reorderList(prevState, dragIndex, hoverIndex));
                          }}
                          handleResetColumns={(originalColumns) => {
                            handleSetColumnsData(originalColumns);
                          }}
                        />
                      )}
                    </>
                  </Card>
                  <Flex justifyContent="flex-end" width={1} mt={10} mb={10}>
                    <Pagination
                      className="ant-pagination"
                      current={state.pagination.currentPage}
                      total={state.pagination.totalItems}
                      pageSize={state.pagination.itemsPerPage}
                      onChange={(current) => {
                        state.pagination.currentPage = current;
                        getUnitListInfo(false);
                        render();
                      }}
                      disabled={state.isLoading}
                    />
                  </Flex>
                </div>
              </AnalisysResult>
            )}

            {state.isLoading && (
            <LoaderOverlay>
              <Loader variant="primary" size="large" />
            </LoaderOverlay>
            )}
          </div>

          {!state.haveAnalisysResult && <ResultContainerOverlay />}
        </ResultContainer>
      )}
    </div>
  );
};

export function sortDataColumn(state, column) {
  Object.keys(state.columnSort).forEach((columnKey) => {
    if (columnKey === column) {
      state.columnSort[columnKey] = {
        column: state.columnSort[columnKey].column,
        desc: !state.columnSort[columnKey].desc,
      };
    } else {
      state.columnSort[columnKey] = {
        column: state.columnSort[columnKey].column,
        desc: false,
      };
    }
  });

  state.currentSort = {
    field: state.columnSort[column].column,
    type: state.columnSort[column].desc ? 'Asc' : 'Desc',
  };
}

const NoDataInTable = () => (
  <NoAnalisysSelected>
    <EmptyDocumentIcon />
    <span>{t('resultadoDaAnalise')}</span>
    <p>
      {t('tabelaSemDados')}
    </p>
  </NoAnalisysSelected>
);
