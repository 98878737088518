import { colors } from '~/styles/colors';
import {
  EnergyEfficiencyBar,
  EnergyEfficiencyTicketData,
  EnergyEfficiencyTicketStyled,
} from './styles';
import { forwardRef, useImperativeHandle, useState } from 'react';

interface EnergyEfficiencyTicketProps {
  ticket: {
    label: string;
    units: number[];
    percentage: number;
    color: string;
    width: string;
  };
  handleClickTicket: (label, units, isPressed) => void;
  ref: React.Ref<{ resetTicket: () => void }>;
}

export const EnergyEfficiencyTicket: React.FC<EnergyEfficiencyTicketProps> = forwardRef(({ ticket, handleClickTicket }, ref) => {
  const [isSelected, setIsSelected] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      resetTicket() {
        setIsSelected(false);
      },
    }),
    [],
  );

  return (
    <EnergyEfficiencyTicketStyled isSelected={isSelected}>
      <EnergyEfficiencyTicketData>
        <p>{ticket.units.length}</p>
        <span>
          {ticket.percentage}
          %
        </span>
      </EnergyEfficiencyTicketData>
      <div
        style={{
          background: colors.GreyDefaultCardBorder,
          width: '1px',
          height: '100%',
        }}
      />
      <EnergyEfficiencyBar
        isSelected={isSelected}
        barColor={ticket.color}
        barWidth={ticket.width}
        onClick={() => setIsSelected((prevState) => {
          handleClickTicket(ticket.label, ticket.units, !prevState);
          return !prevState;
        })}
      >
        <div>
          <span>{ticket.label.toUpperCase()}</span>
        </div>
      </EnergyEfficiencyBar>
    </EnergyEfficiencyTicketStyled>
  );
});
