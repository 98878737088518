import { ReactElement } from 'react';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/NewSelect';

interface ManufacturerProps {
  valveManufOptions: string[];
  state: {
    valveManuf: string | null;
    valveModel: string;
    otherValveManuf: string;
    driApplication: string | null;
  };
  onChangeValveManuf: (item: string) => void;
  onChangeValveModel: (value: string) => void;
  onChangeOtherValveManuf: (value: string) => void;
}

export function Manufacturer({
  state,
  valveManufOptions,
  onChangeOtherValveManuf,
  onChangeValveManuf,
  onChangeValveModel,
}: ManufacturerProps): ReactElement | null {
  const { t } = useTranslation();

  if (state.driApplication !== 'Fancoil' && state.driApplication !== 'VAV') {
    return null;
  }

  return (
    <>
      <Select
        style={{ width: '250px', marginBottom: '20px' }}
        label={state.driApplication === 'VAV' ? t('fabricanteAtuadorVav') : t('fabricanteAtuador')}
        placeholder={t('selecionar')}
        options={valveManufOptions}
        value={state.valveManuf}
        onSelect={(item) => onChangeValveManuf(item)}
      />
      {state.valveManuf === t('outroDigitar') && (
      <Input
        style={{ width: '250px' }}
        label={t('fabricanteAtuador')}
        placeholder={t('digitarFabricante')}
        value={state.otherValveManuf}
        onChange={(event) => onChangeOtherValveManuf(event.target.value)}
      />
      )}
      <Input
        style={{ width: '250px' }}
        label={state.driApplication === 'VAV' ? t('modeloAtuadorVav') : t('modeloAtuador')}
        placeholder={t('digitarModelo')}
        value={state.valveModel}
        onChange={(event) => onChangeValveModel(event.target.value)}
      />
    </>
  );
}
