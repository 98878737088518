export const driApplicationOpts = {
  'Carrier ECOSPLIT': 'carrier-ecosplit',
  'Medidor de Energia': 'medidor-energia',
  VAV: 'vav',
  'Modbus Genérico': 'modbus-generico',
  Fancoil: 'fancoil',
  'Chiller Carrier': 'chiller-carrier',
};

export const driMeterApplications = {
  ET330: 'cg-et330',
  'Nexus II': 'abb-nexus-ii',
  'ETE-30': 'abb-ete-30',
  EM210: 'cg-em210',
  'MULT-K': 'kron-mult-k',
  'MULT-K 05': 'kron-mult-k-05',
  'MULT-K 120': 'kron-mult-k-120',
  'iKRON 03': 'kron-ikron-03',
  'Schneider PM2100': 'schneider-eletric-pm2100',
};

export const driVAVsApplications = {
  'BAC-6000': 'vav-bac-6000',
  'BAC-2000': 'vav-bac-2000',
};

export const driFancoilsApplications = {
  'BAC-6000': 'fancoil-bac-6000',
  'BAC-2000': 'fancoil-bac-2000',
};

export const driChillerCarrierApplications = {
  '30HXE': 'chiller-carrier-30hxe',
  '30GXE': 'chiller-carrier-30gxe',
  '30HXF': 'chiller-carrier-30hxf',
  '30XAB': 'chiller-carrier-30xab',
};

export const driMetersCfgs = {
  ET330: {
    application: 'cg-et330',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: 9600,
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
    telemetryInterval: 300,
  },
  'Nexus II': {
    application: 'abb-nexus-ii',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: 9600,
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
    telemetryInterval: 300,
  },
  'ETE-30': {
    application: 'abb-ete-30',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: 9600,
    parity: 'desabilitado',
    stopBits: '2-stop-bits',
    telemetryInterval: 300,
  },
  EM210: {
    application: 'cg-em210',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: 9600,
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
    telemetryInterval: 300,
  },
  'MULT-K': {
    application: 'kron-mult-k',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: 9600,
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
    telemetryInterval: 300,
  },
  'MULT-K 05': {
    application: 'kron-mult-k-05',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: 9600,
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
    telemetryInterval: 300,
  },
  'MULT-K 120': {
    application: 'kron-mult-k-120',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: 9600,
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
    telemetryInterval: 300,
  },
  'iKRON 03': {
    application: 'kron-ikron-03',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: 9600,
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
    telemetryInterval: 300,
  },
  'Schneider PM2100': {
    application: 'schneider-eletric-pm2100',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: 9600,
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
    telemetryInterval: 300,
  },
};

export const driProtocolsOpts = {
  'Modbus TCP': 'modbus-tcp',
  'Modbus RTU': 'modbus-rtu',
  'Carrier ECOSPLIT': 'carrier-ecosplit',
};

export const driParityOpts = {
  Desabilitado: 'desabilitado',
  Ímpar: 'impar',
  Par: 'par',
};

export const driLayerOpts = {
  'RS-485': 'rs-485',
  'RS-232': 'rs-232',
  Ethernet: 'ethernet',
};

export const driStopBitsOpts = {
  '1 Stop Bit': '1-stop-bit',
  '1.5 Stop Bits': '1.5-stop-bits',
  '2 Stop Bits': '2-stop-bits',
};

export const currentCapacityOpts = [
  { name: '50A', value: '50' },
  { name: '75A', value: '75' },
  { name: '100A', value: '100' },
  { name: '150A', value: '150' },
  { name: '200A', value: '200' },
  { name: '250A', value: '250' },
  { name: '300A', value: '300' },
  { name: '350A', value: '350' },
  { name: '400A', value: '400' },
  { name: '500A', value: '500' },
  { name: '600A', value: '600' },
  { name: '630A', value: '630' },
  { name: '800A', value: '800' },
  { name: '1000A', value: '1000' },
  { name: '1200A', value: '1200' },
  { name: '1500A', value: '1500' },
  { name: '1600A', value: '1600' },
  { name: '2000A', value: '2000' },
];

export const metersWithCurrCapacityConfig = ['ET330'] as string[];

export const installationTypeOpts = {
  ET330: [
    { name: 'Rede Bifásica', value: '2' },
    { name: 'Rede Trifásica sem neutro', value: '1' },
    { name: 'Rede Trifásica com neutro', value: '0' },
  ],
  EM210: [
    { name: 'Rede Bifásica', value: '2' },
    { name: 'Rede Trifásica sem neutro', value: '1' },
    { name: 'Rede Trifásica com neutro', value: '0' },
  ],
  'MULT-K 120': [
    { name: 'Rede Bifásica', value: '2' },
    { name: 'Rede Trifásica sem neutro', value: '1' },
    { name: 'Rede Trifásica com neutro', value: '0' },
  ],
};

export const driApplicationCfgs = {
  ET330: {
    application: 'cg-et330',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  'Nexus II': {
    application: 'abb-nexus-ii',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  'ETE-30': {
    application: 'abb-ete-30',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '2-stop-bits',
  },
  'Carrier ECOSPLIT': {
    application: 'carrier-ecosplit',
    protocol: 'carrier-ecosplit',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  EM210: {
    application: 'cg-em210',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  'MULT-K': {
    application: 'kron-mult-k',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  'MULT-K 05': {
    application: 'kron-mult-k-05',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  'MULT-K 120': {
    application: 'kron-mult-k-120',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  'iKRON 03': {
    application: 'kron-ikron-03',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  'BAC-6000': {
    application: 'vav-bac-6000',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  'BAC-2000': {
    application: 'vav-bac-2000',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  'Schneider PM2100': {
    application: 'schneider-eletric-pm2100',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  '30HXE': {
    application: 'chiller-carrier-30hxe',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  '30GXE': {
    application: 'chiller-carrier-30gxe',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  '30HXF': {
    application: 'chiller-carrier-30hxf',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  '30XAB': {
    application: 'chiller-carrier-30xab',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
  'Chiller-Default': {
    application: 'chiller-carrier-30xab',
    protocol: 'modbus-rtu',
    serialMode: 'rs-485',
    modbusBaudRate: '9600',
    parity: 'desabilitado',
    stopBits: '1-stop-bit',
  },
} as {
  [key: string]: {
    application: string,
    protocol: string,
    serialMode: string,
    modbusBaudRate: string,
    parity: string,
    stopBits: string,
  }
};
