import { colors } from '~/styles/colors';
import {
  ConsumptionResultContainer,
  ConsumptionResultData,
  ConsumptionStyled,
  Content,
  DeltaInfo,
  EnergyEfficiencyHeader,
  EnergyEfficiencyStyled,
  NoAnalisysWarning,
  TooltipContainer,
} from './styles';
import { EnergyEfficiencyTicket } from './EnergyEfficiencyTicket';
import { useCard } from '~/contexts/CardContext';
import { useEffect, useRef } from 'react';
import { ApiResps, apiCall } from '~/providers';
import { useStateVar } from '~/helpers/useStateVar';
import { handleGetDatesParams } from '~/helpers/getRangeParamsChart';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { formatNumber } from '~/helpers/formatNumber';
import ReactTooltip from 'react-tooltip';
import { InfoIcon } from '~/icons';
import { useEnergyCard } from '../EnergyCardContext';
import { convertEnergy } from '~/helpers';
import { ConsumptionChart } from '../ConsumptionChart';

const tickets = [
  {
    label: 'a',
    color: '#039340',
    width: '20%',
  },
  {
    label: 'b',
    color: '#05A935',
    width: '35%',
  },
  {
    label: 'c',
    color: '#99D014',
    width: '45%',
  },
  {
    label: 'd',
    color: '#FDF900',
    width: '55%',
  },
  {
    label: 'e',
    color: '#F1AE02',
    width: '65%',
  },
  {
    label: 'f',
    color: '#E4650A',
    width: '80%',
  },
  {
    label: 'g',
    color: '#DE2917',
    width: '100%',
  },
];

interface FilterDatesProps {
  yearOptions: {
    label: string;
    value: string | number;
    hasData: boolean;
  }[];
  monthOptions: {
    label: string;
    value: string | number;
    hasData: boolean;
  }[];
}

interface EnergyCardInsightsProps {
  energyCardFilters: {
    startObject?: boolean;
    unitIds?: number[];
    stateIds?: number[];
    cityIds?: string[];
    supervisorIds?: string[];
  };
  insideFilters: {
    clientIds: [];
    stateIds: [];
    cityIds: [];
    unitIds: [];
  };
  filterDatesChart: FilterDatesProps;
  isLoading: boolean;
  handleChangeProcellFilters: (units) => void;
  handleClickBarChart: (name) => void;
}

export const EnergyInsights: React.FC<EnergyCardInsightsProps> = ({
  energyCardFilters,
  insideFilters,
  isLoading,
  filterDatesChart,
  handleChangeProcellFilters,
  handleClickBarChart,
}) => {
  const { cardDate, handleSetTabIsLoading } = useEnergyCard();

  const ticketsRef = useRef([] as any);

  const [state, render] = useStateVar({
    energyInsightsData: [] as any,
    energyInsightsSummary: {} as any,
    historyChartData: {} as ApiResps['/energy/get-energy-analysis-hist'],
    procellFilters: {} as any,
  });

  const { cards } = useCard();
  const energyCard = cards.find((card) => card.title === 'Energia');

  const handleGetInsightsProcel = async (options?) => {
    try {
      const datesParams = handleGetDatesParams(
        `${cardDate.month} ${cardDate.year}`,
        'MMMM YYYY',
        'monthMode',
        false,
      );

      const data = await apiCall('/energy/get-procel-insights', {
        insideFilters: {
          ...insideFilters,
        },
        ...datesParams,
        ...energyCardFilters,
        ...options,
      });

      state.energyInsightsSummary = {
        containsProcel: data.containsProcel,
        containsAnalysisData: data.containsAnalysisData,
        averageConsumption: data.averageConsumption,
        totalConsumption: data.totalConsumption,
        totalCharged: data.totalCharged,
        averageConsumptionPreviousMonthPercentage:
          data.averageConsumptionPreviousMonthPercentage,
      };

      state.energyInsightsData = tickets.map((ticket) => ({
        ...ticket,
        ...data[ticket.label],
      }));
    } catch (e) {
      toast.error(t('naoFoiPossivelBuscarInformacoesClassificacao'));
    } finally {
      render();
    }
  };

  const handleGetChartData = async (energyHistParams) => {
    try {
      const chartModeParam = 'month';

      const datesParams = handleGetDatesParams(
        `${cardDate.month} ${cardDate.year}`,
        'MMMM YYYY',
        'monthMode',
        false,
      );

      const data = await apiCall('/energy/get-energy-analysis-hist', {
        ...datesParams,
        filterType: chartModeParam,
        insideFilters: { ...insideFilters },
        ...energyCardFilters,
        ...energyHistParams,
      });
      state.historyChartData = data;
    } catch (e) {
      toast.error(t('naoFoiPossivelBuscarInformacoesGrafico'));
    } finally {
      render();
    }
  };

  const handleFormatAndConvertNumber = (number) => {
    const [convertedNumber, numberUnit] = convertEnergy(number ?? 0);

    return [formatNumber(convertedNumber), numberUnit];
  };

  const handleLoadData = async () => {
    handleSetTabIsLoading(true, 'insights');

    const procellArrays = Object.values(state.procellFilters);
    const procellUnits = [...procellArrays.flat()];

    await handleGetInsightsProcel({ ...(procellUnits.length > 0 && { procelUnitsFilter: procellUnits }) });
    await handleGetChartData({ ...(procellUnits.length > 0 && { unitIds: procellUnits }) });

    handleSetTabIsLoading(false, 'insights');
  };

  useEffect(() => {
    if (energyCardFilters.startObject || !cardDate.verifyDate) return;
    handleLoadData();
  }, [cardDate]);

  useEffect(() => {
    ticketsRef.current.forEach((ticketRef) => ticketRef.resetTicket());
  }, [energyCardFilters]);

  return (
    <Content>
      {!state.energyInsightsSummary.containsAnalysisData ? (
        <NoAnalisysWarning>
          <svg
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.1765 10.9H5.47059M7.70588 15.3H5.47059M14.4118 6.5H5.47059M18.8824 12V6.28C18.8824 4.43183 18.8824 3.50774 18.5169 2.80183C18.1954 2.1809 17.6825 1.67606 17.0516 1.35968C16.3344 1 15.3955 1 13.5176 1H6.36471C4.48688 1 3.54797 1 2.83074 1.35968C2.19984 1.67606 1.68691 2.1809 1.36545 2.80183C1 3.50774 1 4.43183 1 6.28V17.72C1 19.5682 1 20.4923 1.36545 21.1982C1.68691 21.8191 2.19984 22.3239 2.83074 22.6403C3.54797 23 4.48688 23 6.36471 23H9.94118M14.4118 16.4L20 21.9M20 16.4L14.4118 21.9"
              stroke="#7D7D7D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>{t('naoHaDados')}</p>
          <span>{t('paraGerarAnalise')}</span>
        </NoAnalisysWarning>
      ) : (
        <>
          {state.energyInsightsSummary.containsProcel && (
            <EnergyEfficiencyStyled>
              <EnergyEfficiencyHeader>
                <h3>{t('Eficiência Energética')}</h3>
                <span>{t('unidades')}</span>
              </EnergyEfficiencyHeader>
              {state.energyInsightsData.map((ticket, index) => (
                <EnergyEfficiencyTicket
                  key={`energy-insigths-ticket-${ticket.label}`}
                  ref={(ticket) => (ticketsRef.current[index] = ticket)}
                  ticket={ticket}
                  handleClickTicket={(label, units, isPressed) => {
                    if (isPressed) {
                      state.procellFilters[label] = units;
                    } else {
                      state.procellFilters[label] = [];
                    }

                    const procellArrays = Object.values(state.procellFilters);
                    const procellUnits = [...procellArrays.flat()];

                    handleChangeProcellFilters(procellUnits);
                  }}
                />
              ))}
            </EnergyEfficiencyStyled>
          )}
          {state.energyInsightsSummary.containsProcel && (
            <div
              style={{
                background: colors.GreyDefaultCardBorder,
                width: '1px',
                height: '100%',
              }}
            />
          )}
          <ConsumptionStyled isExpanded={energyCard?.isExpanded}>
            {energyCard?.isExpanded && (
              <ConsumptionChart
                chartData={state.historyChartData}
                chartMode="monthMode"
                cardYear={cardDate.year}
                cardMonth={cardDate.month}
                filterDatesChart={filterDatesChart}
                isLoading={isLoading}
                handleClickBarChart={handleClickBarChart}
                handleChangeComparingChart={() => {}}
                canCompare={false}
                chartWrapperStyle={{
                  height: '235px',
                }}
                consumptionChartStyle={{
                  padding: '5px 0 24px',
                }}
              />
            )}
            <h3>{t('consumo')}</h3>
            <ConsumptionResultContainer isExpanded={energyCard?.isExpanded}>
              <ConsumptionResultData>
                <span>{t('mediaGeral')}</span>
                <p>
                  {
                    handleFormatAndConvertNumber(
                      state.energyInsightsSummary.averageConsumption,
                    )[0]
                  }
                  {' '}
                  <span>
                    {
                      handleFormatAndConvertNumber(
                        state.energyInsightsSummary.averageConsumption,
                      )[1]
                    }
                    h/m²
                  </span>
                </p>
                {state.energyInsightsSummary
                  .averageConsumptionPreviousMonthPercentage && (
                  <DeltaInfo
                    isPositive={
                      state.energyInsightsSummary
                        .averageConsumptionPreviousMonthPercentage > 0
                    }
                  >
                    <div>
                      <svg
                        width="7"
                        height="6"
                        viewBox="0 0 7 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.85156 6L6.88265 0.75H0.820474L3.85156 6Z"
                          fill="#5AB365"
                        />
                      </svg>
                    </div>

                    <p>
                      {state.energyInsightsSummary
                        .averageConsumptionPreviousMonthPercentage > 0 && '+'}
                      {
                        state.energyInsightsSummary
                          .averageConsumptionPreviousMonthPercentage
                      }
                      %
                    </p>

                    <InfoIcon
                      width="12"
                      color="#B6B6B6"
                      data-tip="delta-procell-summary"
                      data-for="delta-procell-summary"
                    />
                    <ReactTooltip
                      id="delta-procell-summary"
                      place="top"
                      effect="solid"
                    >
                      <TooltipContainer>
                        <p>{t('variacaoConsumo')}</p>
                        <span>
                          {t('porcentagemEmRelacao')}
                          <br />
                          {t('aoConsumoAnterior')}
                        </span>
                      </TooltipContainer>
                    </ReactTooltip>
                  </DeltaInfo>
                )}
              </ConsumptionResultData>
              <ConsumptionResultData>
                <span>
                  {t('somatorioGeral')}
                  {' '}
                  (
                  {
                    handleFormatAndConvertNumber(
                      state.energyInsightsSummary.totalConsumption,
                    )[1]
                  }
                  h)
                </span>
                <p>
                  {
                    handleFormatAndConvertNumber(
                      state.energyInsightsSummary.totalConsumption,
                    )[0]
                  }
                  {' '}
                  <span>
                    {
                      handleFormatAndConvertNumber(
                        state.energyInsightsSummary.totalConsumption,
                      )[1]
                    }
                    h
                  </span>
                </p>
              </ConsumptionResultData>
              <ConsumptionResultData>
                <span>
                  {t('somatorioGeral')}
                  {' '}
                  (R$)
                </span>
                <p>
                  <span> R$ </span>
                  {formatNumber(state.energyInsightsSummary.totalCharged ?? 0)}
                </p>
              </ConsumptionResultData>
            </ConsumptionResultContainer>
          </ConsumptionStyled>
        </>
      )}
    </Content>
  );
};
