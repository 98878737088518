import { ContainerCardWater } from '../style';
import {
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import * as echarts from 'echarts/core';
import { useContext, useEffect, useRef } from 'react';
import { thousandPointFormat } from '~/helpers/thousandFormatNumber';
import { t } from 'i18next';
import { ApiResps } from '~/providers';
import { ICard } from '~/contexts/CardContext';
import { getUserProfile } from '~/helpers/userProfile';
import { INCOERENT_ICON, WARNING_ICON } from '~/components/EnergyCard/EnergyHistory';
import MenuContext from '~/contexts/menuContext';

echarts.use([
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  CanvasRenderer,
  UniversalTransition,
  BarChart,
]);

interface TooltipParams {
  name: string; // Nome da categoria
  value: number; // Valor do ponto de dados
  seriesName: string; // Nome da série
  seriesType: string; // Tipo da série
  dataIndex: number; // Índice do ponto de dados
  color: string; // Cor da série
}
const namesMonths = [t('Jan'), t('Fev'), t('Mar'), t('Abr'), t('Mai'), t('Jun'), t('Jul'), t('Ago'), t('Set'), t('Out'), t('Nov'), t('Dez')];
const completeNameMonths = [t('mesesDoAno.janeiro'), t('mesesDoAno.fevereiro'), t('mesesDoAno.marco'), t('mesesDoAno.abril'), t('mesesDoAno.maio'), t('mesesDoAno.junho'), t('mesesDoAno.julho'), t('mesesDoAno.agosto'), t('mesesDoAno.setembro'), t('mesesDoAno.outubro'), t('mesesDoAno.novembro'), t('mesesDoAno.dezembro')];

export function GenerateGraphWater({
  arrayData, waterCard, isExpanded, unitMeasuere,
} : Readonly<{
  arrayData: ApiResps['/dma/get-month-usage']['history'],
  waterCard: ICard | undefined,
  isExpanded: boolean | undefined,
  unitMeasuere: string,
}>) {
  const chartRef = useRef<HTMLDivElement>(null);
  const { menuToogle } = useContext(MenuContext);
  let arrayDataFiltered;
  let xAxisNames;
  const profile = getUserProfile();
  const isAdmin = profile.permissions?.isAdminSistema;
  const isDesktop = window.matchMedia('(min-width: 426px)');
  const isMobile = !isDesktop.matches;

  const getConsumptionWater = (consumption: number) => {
    if (!unitMeasuere || unitMeasuere === 'cubic') { return `${(consumption / 1000).toFixed(2)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`; }
    if (unitMeasuere === 'liters') { return `${thousandPointFormat(consumption)}`; }
    return '';
  };

  function formatterTooltip(value: TooltipParams[]) {
    if (!arrayData[value[0].dataIndex]?.usage_month && !arrayData[value[0].dataIndex]?.number_devices) {
      return `
      <div style="display: flex; flex-direction: column; gap: 8px; padding: 12px;">
        <div style="display: flex; flex-direction: column; gap: 8px; color: #000000;">
          <span style="font-weight: 700; font-size: 12px; font-family: Inter;"><strong>${completeNameMonths[arrayData[value[0].dataIndex].history_month]} ${arrayData[value[0].dataIndex].year}</strong></span>
          <div style="display: flex; gap: 8px;">
            <svg style="margin-top: 4px;" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.085 9.519V7.085M7.085 4.651H7.09109M13.17 7.085C13.17 10.4457 10.4457 13.17 7.085 13.17C3.72435 13.17 1 10.4457 1 7.085C1 3.72435 3.72435 1 7.085 1C10.4457 1 13.17 3.72435 13.17 7.085Z" stroke="#565656" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div style="display: flex; flex-direction: column; gap: 8px;">
              <span style="font-weight: 700; font-size: 12px; font-family: Inter; color: #565656;">${t('semMonitoramento')}</span>
              <span style="font-weight: 400; font-size: 11px; font-family: Inter; color: #737373;">${t('paraMaisDetalhesHTML')}</span>
            </div>
          </div>
        </div>
      </div>
      `;
    }
    const consumo = `
    <div style="display: flex; flex-direction: column; gap: 8px; padding: 12px;">
      <div style="display: flex; flex-direction: column; gap: 8px; color: #000000;">
        <span style="font-weight: 700; font-size: 12px; font-family: Inter;"><strong>${completeNameMonths[arrayData[value[0].dataIndex].history_month]} ${arrayData[value[0].dataIndex].year}</strong></span>
        <div style="display: flex; gap: 8px;">
          <div style="width: 15px; height: 15px; border-radius: 5px; background-color: ${arrayData[value[0].dataIndex]?.usage_month < 0 && isAdmin ? '#F3B107' : '#2D81FF'}; margin-top: 3px;"></div>
          <div style="display: flex; flex-direction: column;">
            <span style="font-weight: 700; font-size: 10px; font-family: Inter;">${t('consumo')}: </span>
            <span style="font-weight: 400; font-size: 11px; font-family: Inter;"><strong>${getConsumptionWater(arrayData[value[0].dataIndex]?.usage_month || 0)}</strong>${unitMeasuere === 'liters' ? 'L' : 'm³'}</span>
          </div>
        </div>
        <span style="font-weight: 400; font-size: 11px; margin-left: 22px;"><strong style="font-weight: 700; font-size: 10px; font-family: Inter;">${t('dispositivosContabilizados')}: </strong>${arrayData[value[0].dataIndex]?.number_devices}</span>
      </div>
    </div>
    `;
    const tooltipContentFactory = [consumo];
    return tooltipContentFactory.join('<br />');
  }
  function formatNumber(number: number): string {
    const numberString = number.toFixed(2);
    const parts = numberString.split('.');
    const integerPart = parts[0];
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const formattedNumber = `${formattedIntegerPart},${parts[1]}`;
    return formattedNumber;
  }

  function formatArrayDataFiltered() {
    if (arrayData) {
      if (unitMeasuere === 'liters') {
        arrayDataFiltered = arrayData.map((item) => item.usage_month.toFixed(2));
      } else {
        arrayDataFiltered = arrayData.map((item) => (item.usage_month / 1000).toFixed(2));
      }
      xAxisNames = arrayData.map((item) => {
        let isProblemData = '';
        if (item.usage_month < 0) isProblemData = '\n{image|    }';
        return {
          value: `${namesMonths[item.history_month]}\n{bold|${isMobile ? item.year?.toString().slice(-2) : item.year}}${isProblemData}`,
        };
      });
    } else {
      arrayDataFiltered = [];
    }
  }

  const formatSeriesChart = (item) => {
    if (!item || (!isAdmin && item < 0)) {
      return 0;
    }

    if (isAdmin && item < 0) {
      return {
        value: item,
        itemStyle: {
          color: '#F3B107',
          borderRadius: [0, 0, 5, 5],
        },
      };
    }

    return item;
  };

  const handleRenderWater = () => {
    formatArrayDataFiltered();
    arrayDataFiltered = arrayDataFiltered.map((item) => formatSeriesChart(item));
    if (chartRef.current) {
      echarts.dispose(chartRef.current);
      const chartInstance = echarts.init(chartRef.current);
      window.addEventListener('resize', () => {
        chartInstance.resize();
      });
      chartInstance.setOption({
        grid: {
          left: '5%',
          right: '5%',
          bottom: '2%',
          width: '90%',
          top: '10%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          formatter: (value) => formatterTooltip(value),
          axisPointer: {
            type: 'shadow',
          },
          shadowStyle: {
            shadowBlur: 2,
            shadowOffsetY: 0,
            shadowOffsetX: 1,
          },
          lineStyle: {
            width: '40px',
          },
        },
        xAxis: [{
          type: 'category',
          data: xAxisNames,
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
          axisLabel: {
            show: true,
            align: 'center',
            margin: 8,
            showMaxLabel: true,
            interval: 0,
            color: '#000',
            fontFamily: 'Inter',
            fontSize: isExpanded ? '12' : '10',
            rich: {
              bold: {
                fontWeight: 'bold',
                color: '#000',
                fontFamily: 'Inter',
                fontSize: isExpanded ? '12' : '10',
              },
              image: {
                backgroundColor: {
                  image: isAdmin ? WARNING_ICON : INCOERENT_ICON,
                },
              },
            },
          },
          axisLine: { show: false },
          axisTick: { show: false },
        }],
        yAxis: [
          {
            type: 'value',
            splitNumber: 5,
            position: 'left',
            axisLabel: {
              formatter: (value) => (`${formatNumber(value)} ${unitMeasuere === 'liters' ? 'L' : 'm³'}`),
              align: 'right',
              fontSize: 9,
              color: '#000',
              fontFamily: 'Inter',
            },
            offset: 18,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: {
              lineStyle: {
                type: 'dashed',
              },
            },
          },
        ],
        series: [
          {
            type: 'bar',
            cursor: 'default',
            barWidth: isMobile ? '12px' : isExpanded ? '30px' : '20px',
            data: arrayDataFiltered,
            color: '#2D81FF',
            barCategoryGap: '10%',
            itemStyle: {
              borderRadius: [5, 5, 0, 0],
              padding: [0, 20, 0, 0], // Definindo o raio de borda para o canto superior esquerdo e superior direito
            },
          },
        ],
      });
      window.addEventListener('resize', () => {
        chartInstance.resize();
      });
    }
  };

  useEffect(() => {
    handleRenderWater();
  }, [waterCard?.isExpanded, arrayData, isExpanded, menuToogle]);

  return (
    <ContainerCardWater>
      <div id="card-water" ref={chartRef} style={{ width: '100%', height: '100%' }} />
    </ContainerCardWater>
  );
}
